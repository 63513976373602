import { addAmbassador, getAllAmbassadors, getAmbassador, deleteAmbassador, updateAmbassador } from "../actionTypes/types";
  
  const ambassadorReducer = (state = [], action) => {
    switch (action.type) {
      case addAmbassador:
        // Assuming action.payload contains a single ambassador object
        return [...state, action.payload];
        case getAmbassador:
        // Find and return the ambassador with the given ID
        return action.payload;
        case getAllAmbassadors:
          // Assuming action.payload contains an array of ambassador objects
          return action.payload;
      case updateAmbassador:
          console.log(action.payload,'the action payload')
        // Find the index of the ambassador with the matching ID
        return state.map((ambassador) =>
          ambassador.id === action.payload.id ? action.payload : ambassador
        );
      case deleteAmbassador:
        // Remove the ambassador with the given ID from the state
        return state.filter((ambassador) => ambassador.id !== action.payload.id);
      default:
        return state;
    }
  };
  
  export default ambassadorReducer;
  