import { getAllBlackListDevices, updateDevices, addBlockedDevice, deleteBlockedDevice } from "../actionTypes/types";

const initialState = [];
const blockedDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAllBlackListDevices:
      return action.payload;
    case updateDevices:
      return state.map((device) =>
        device.id === action.payload.id ? action.payload : device
      );
    case addBlockedDevice: 
      return [...state, action.payload];
    case deleteBlockedDevice: 
      return state.filter((device) => device.id !== action.payload);
    default:
      return state;
  }
};

export default blockedDeviceReducer;
