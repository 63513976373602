/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGamesOfMonth, addGameOfMonthReward } from "../service/actions/gameOfMonthActions"; // Import the actions
import { Breadcrumb, Table, Collapse } from "react-bootstrap";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import Navigation from "../components/Navigation/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchRewardsModal from "../components/modal/SearchRewardsModal"; // Import the modal component

const GameOfMonthRewards = () => {
  const [onSideMenu, setSideMenu] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]); // Store expanded row ids
  const [loading, setLoading] = useState(true); // Loading state
  const [showModal, setShowModal] = useState(false); // Modal state for showing and hiding
  const dispatch = useDispatch();

  // Fetch games from Redux store
  const games = useSelector((state) => state.gameOfMonth.games);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true); // Set loading to true before dispatch
      await dispatch(getAllGamesOfMonth()); // Dispatch to fetch data
      setLoading(false); // Set loading to false after dispatch
    };

    fetchGames();
  }, [dispatch]);

  // Toggle the expanded row when clicked
  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(id)) {
        return prevExpandedRows.filter((rowId) => rowId !== id); // Collapse row
      } else {
        return [...prevExpandedRows, id]; // Expand row
      }
    });
  };

  const handleAddData = () => {
    setShowModal(true); // Show the modal when Add Data is clicked
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
  };

  // Add Rewards Function
  const handleAddReward = async (payload) => {
    try {
      const response = dispatch(addGameOfMonthReward(payload.g_id, payload.rewards));

      if (response && response.status === "success") {
        console.log("Game of the Month rewards added successfully.");
      } else {
        console.error("Failed to add rewards:", response?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error adding rewards:", error);
    }
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={setSideMenu} />
      <div className={`main-content d-flex flex-column ${onSideMenu ? "hide-sidemenu" : ""}`}>
        <div className="main-content-header">
          <Breadcrumb>
            <h1 style={{ marginBottom: "40px" }}>Game Of Month With Rewards</h1>
          </Breadcrumb>

          {/* Add Button */}
          <div className="d-flex justify-content-end mb-3">
            <div
              onClick={handleAddData}
              style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px", paddingRight: "20px" }}
            >
              <span>
                <p style={{ paddingTop: "5px", fontSize: "17px", paddingRight: "5px" }}>Add Data</p>
              </span>
              <FontAwesomeIcon icon={faSquarePlus} style={{ fontSize: "30px", marginTop: "-15px" }} />
            </div>
          </div>

          {/* "Table" Heading Centered */}
          <div style={{ textAlign: "center", marginBottom: "0px", marginTop: "-50px" }}>
            <h3>Table</h3>
          </div>
        </div>

        <div className="main-content-body">
          <div style={{ maxHeight: "500px", overflow: "hidden" }}>
            {/* Table Header */}
            <div style={{ overflowX: "auto" }}>
              <Table bordered hover responsive style={{ tableLayout: "fixed", width: "99.4%" }}>
                <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#fff" }}>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Game ID</th>
                    <th className="text-center">Game Icon</th>
                    <th className="text-center">Game Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Start Date</th>
                    <th className="text-center">End Date</th>
                    <th className="text-center">Created At</th>
                  </tr>
                </thead>
              </Table>
            </div>

            {/* Table Body with Scroll */}
            <div style={{ maxHeight: "440px", overflowY: "auto", width: "100%", marginTop: "-10px" }}>
              <Table bordered hover responsive style={{tableLayout: "fixed", width: games.length * 50 > 440 ? "100%" : "99.4%"}}>
                <tbody>
                  {games.map((row) => (
                    <React.Fragment key={row.id}>
                      <tr onClick={() => toggleRow(row.id)} style={{ cursor: "pointer" }}>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {row.id}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {row.g_id}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          <img
                            src={row.Game?.game_icon}
                            alt={" "}
                            style={{ width: "45px", height: "45px", objectFit: "cover", borderRadius: "10%" }}
                          />
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {row.Game.name}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {row.status}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {new Date(row.startDate).toLocaleDateString()}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {new Date(row.endDate).toLocaleDateString()}
                        </td>
                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                          {new Date(row.createdAt).toLocaleDateString()}
                        </td>
                      </tr>
                      {expandedRows.includes(row.id) && (
                        <tr>
                          <td colSpan="8" style={{ padding: 0 }}>
                            <Collapse in={expandedRows.includes(row.id)}>
                              <div
                                style={{
                                  backgroundColor: "#F3F6F9",
                                  padding: "10px",
                                  borderTop: "1px solid #dee2e6",
                                }}
                              >
                                <h5>Rewards</h5>
                                <Table bordered hover size="sm">
                                  <thead>
                                    <tr>
                                      <th className="text-center">ID</th>
                                      <th className="text-center">Rank</th>
                                      <th className="text-center">Reward Type</th>
                                      <th className="text-center">Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {row.GameOfMonthRewards.map((reward) => (
                                      <tr key={reward.id}>
                                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                                          {reward.id}
                                        </td>
                                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                                          {reward.rank}
                                        </td>
                                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                                          {reward.rewardType}
                                        </td>
                                        <td className="text-center" style={{ verticalAlign: "middle" }}>
                                          {reward.amount}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* SearchRewardsModal Component */}
      <SearchRewardsModal
        show={showModal}
        handleClose={handleModalClose}
        games={games}
        handleAddReward={handleAddReward}
      />
    </div>
  );
};

export default GameOfMonthRewards;
