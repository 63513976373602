import http from "../../utils/Api";

const getDeviceInformation =
  (page, limit = 10, setPageCount, gyrpscope, accelerometer, deviceId, email, mobile) =>
  async (dispatch) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      let url = `/api/admin/getAllPlayersDeviceInformation?page=${page}&limit=${limit}`;

      // Append gyroscope filter if it's a boolean
      if (gyrpscope === 'true' || gyrpscope === "false") {
        url += `&gyrpscope=${gyrpscope}`;
      }

      // Append accelerometer filter if it's a boolean
      if (accelerometer === 'true' || accelerometer === "false") {
        url += `&accelerometer=${accelerometer}`;
      }

      // Append deviceId filter
      if (deviceId) {
        // Check if the first character is an alphabet
        if (/^[a-zA-Z]/.test(deviceId)) {
          // Normalize the deviceId by capitalizing the first letter and making the rest lowercase
          deviceId = deviceId.charAt(0).toUpperCase() + deviceId.slice(1).toLowerCase();
        }
        url += `&deviceId=${deviceId}`;
      }

      // Append email filter
      if (email) {
        url += `&email=${email}`;
      }

      // Append mobile filter
      if (mobile) {
        url += `&mobile=${encodeURIComponent(mobile)}`;
      }

      const res = await http.get(
        url,
        http.generateConfig(accessToken) // Ensure accessToken is available in your context
      );
      setPageCount(res.totalPages);
      if (res) {
        dispatch({
          type: "deviceInformation",
          payload: res.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export { getDeviceInformation };
