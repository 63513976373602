import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Button, Spinner, Table } from "react-bootstrap";
import { getBlockedDevices, updateBlockedDeviceAction, addBlockedDeviceAction, deleteBlockedDeviceAction } from "../service/actions/blockedDeviceActions";
import Navigation from "../components/Navigation/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileCsv, faUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import EditFieldModal from "../components/modal/EditFieldModal";
import { Input } from "rsuite";
import { saveAs } from "file-saver"; // For CSV Export
import ConfirmationModal from "../components/modal/ConfirmBlockDeviceModal"; 
import DeleteDeviceModal from "../components/modal/DeleteFieldModal";

const columns = [
  { name: "ID", key: "id" },
  { name: "Device ID", key: "deviceId" },
];

const BlockedDevices = () => {
  const [onSideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [deviceId, setDeviceId] = useState(""); // For device ID input
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedField, setSelectedField] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false); // State for confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal

  const dispatch = useDispatch();
  const blockedDevices = useSelector((state) => state.blockedDevice);

  const fetchBlockedDevices = useCallback(async () => {
    try {
      const devicesData = await getBlockedDevices();
      dispatch({ type: "getAllBlackListDevices", payload: devicesData });
    } catch (error) {
      console.error("Error fetching blocked devices:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBlockedDevices();
  }, []);

  const handleChangeINPut = (e) => {
    setDeviceId(e);
  };

  const handleOpenModal = (row, key) => {
    setSelectedRow(row);
    setSelectedField(key);
    setFieldValue(row[key].trim());
    setOpenEditModal(true);
  };

  const handleSaveField = async () => {
    const updatedData = { [selectedField]: fieldValue.trim() };
    const id = selectedRow.id;

    try {
      await dispatch(updateBlockedDeviceAction(updatedData, id));
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error saving the device update:", error);
    }
  };

  const handleExportCSV = () => {
    if (!Array.isArray(blockedDevices) || blockedDevices.length === 0) {
      alert("No data to export.");
      return;
    }

    const filteredColumns = columns.filter((column) => column.key !== "id");
    const csvContent = [
      filteredColumns.map((column) => column.name).join(","),
      ...blockedDevices.map((device) => filteredColumns.map((column) => device[column.key] || "").join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "blocked_devices.csv");
  };

  const handleUploadCSV = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const rows = content.split("\n").slice(1);
        const newDevices = rows
          .filter((row) => row.trim())
          .map((row) => {
            const [id, deviceId] = row.split(",");
            return { id: id?.trim(), deviceId: deviceId?.trim() };
          });
        dispatch({ type: "addUploadedDevices", payload: newDevices });
      };
      reader.readAsText(file);
    }
  };

  const handleConfirmBlock = async () => {
    if (!deviceId.trim()) {
      alert("Please enter a valid device ID.");
      return;
    }
    try {
      const response = await addBlockedDeviceAction(deviceId);
      if (response) {
        console.log("Device blocked successfully!");
        fetchBlockedDevices();  // Refresh the list of blocked devices after adding
      } else {
        alert("Failed to block the device.");
      }
    } catch (error) {
      alert("An error occurred while blocking the device.");
    }
    setShowConfirmModal(false);
  };


  const handleDelete = async () => {
    try {
      await dispatch(deleteBlockedDeviceAction(deviceId));  
      fetchBlockedDevices();  
      setShowDeleteModal(false);  
    } catch (error) {
      console.error("Error deleting device:", error);
      alert("An error occurred while deleting the device.");
    }
  };

  const handleOpenDeleteModal = (deviceId) => {
    setDeviceId(deviceId);  
    setShowDeleteModal(true);  
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={setSideMenu} />
      <div className={`main-content d-flex flex-column ${onSideMenu ? "hide-sidemenu" : ""}`}>
        <div className="main-content-header">
          <Breadcrumb>
            <h1 style={{ marginBottom: "40px" }}>Blocked Devices</h1>
          </Breadcrumb>
          <Input
            type="text"
            placeholder="Enter Device Id"
            value={deviceId}
            onChange={handleChangeINPut}
            style={{ marginRight: "3px", padding: "3px" }}
          />
          <Button variant="primary" onClick={() => setShowConfirmModal(true)}>
            Submit
          </Button>{" "}
          {/* Open confirmation modal */}
          <Button variant="info" onClick={handleExportCSV} style={{ marginLeft: "10px" }}>
            <FontAwesomeIcon icon={faFileCsv} style={{ marginRight: "5px" }} /> Export to CSV
          </Button>
          <label htmlFor="csvUpload" style={{ marginLeft: "10px", cursor: "pointer" }}>
            <FontAwesomeIcon icon={faUpload} style={{ marginRight: "5px" }} /> Upload CSV
            <input type="file" id="csvUpload" accept=".csv" onChange={handleUploadCSV} style={{ display: "none" }} />
          </label>
        </div>

        <div className="max-width-container" style={{ width: "100%", margin: "0 auto" }}>
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <div>
              <Table bordered hover responsive style={{ marginTop: "20px", width: "100%" }}>
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index}>{column.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(blockedDevices) && blockedDevices.length > 0 ? (
                    blockedDevices.map((device, index) => (
                      <tr key={index}>
                        {columns.map((column, colIndex) => (
                          <td key={colIndex}>
                            {device[column.key]}
                            {column.key === "deviceId" && (
                              <>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    color: "blue",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpenModal(device, column.key)}
                                />
                                <FontAwesomeIcon
                                  icon={faTrashAlt}  // FontAwesome delete icon
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                    color: "red",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => handleOpenDeleteModal(device.id)}  // Trigger the delete modal with the device ID
                                />
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length}>No blocked devices found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>

      {/* EditFieldModal component */}
      <EditFieldModal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        title={`Edit ${selectedField}`}
        bodyContent={
          <div className="form-container">
            <label className="form-label">{selectedField}</label>
            <input
              className="form-input"
              type="text"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
            />
          </div>
        }
        actionButtonText="Save"
        onAction={handleSaveField}
      />

      {/* ConfirmationModal component */}
      <ConfirmationModal
  show={showConfirmModal}
  onHide={() => setShowConfirmModal(false)}
  message={<>Are you sure you want to block the Device ID: <strong>{deviceId}</strong> ?</>}
  onConfirm={handleConfirmBlock}
/>


      {/* Delete Device Modal */}
      <DeleteDeviceModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        message={<>Are you sure you want to delete the device with ID: <strong>{deviceId}</strong> ?</>}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default BlockedDevices;
