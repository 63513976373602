import React from "react";
import { Modal, Button, Card, Form } from "react-bootstrap";

const PlayerDetailModal = ({ show, handleClose, player }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Player Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form>
          <Form.Group className="mb-3 row align-items-center">
            <Form.Label className="col-sm-4 col-form-label">ID:</Form.Label>
            <div className="col-sm-8">
              <Form.Control type="text" value={player.id} disabled />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 row align-items-center">
            <Form.Label className="col-sm-4 col-form-label">Email:</Form.Label>
            <div className="col-sm-8">
              <Form.Control type="email" value={player.email} disabled />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 row align-items-center">
            <Form.Label className="col-sm-4 col-form-label">Phone:</Form.Label>
            <div className="col-sm-8">
              <Form.Control type="tel" value={player.mobile} disabled />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 row align-items-center">
            <Form.Label className="col-sm-4 col-form-label">Status:</Form.Label>
            <div className="col-sm-8">
              <Form.Control
                type="text"
                value={player.isActive ? "Active" : "Suspended"}
                disabled
                className={player.isActive ? "text-success" : "text-danger"}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 row align-items-center">
            <Form.Label className="col-sm-4 col-form-label">
              Device ID:
            </Form.Label>
            <div className="col-sm-8">
              <Form.Control type="text" value={player.deviceId} disabled />
            </div>
          </Form.Group>
        </Form> */}
        <Form>
          <Form.Group className="mb-4">
            <Form.Label>ID:</Form.Label>
            <Form.Control type="text" value={player.id} disabled />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Email:</Form.Label>
            <Form.Control type="email" value={player.email} disabled />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Phone:</Form.Label>
            <Form.Control type="tel" value={player.mobile} disabled />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Status:</Form.Label>
            <Form.Control
              type="text"
              value={player.isActive ? "Active" : "Suspended"}
              disabled
              className={player.isActive ? "text-success" : "text-danger"}
            />
          </Form.Group>

          {/* <Form.Group className="mb-4">
        <Form.Label>Device ID:</Form.Label>
        <Form.Control type="text" value={player.deviceId} disabled />
      </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlayerDetailModal;
