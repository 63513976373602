import { useEffect, useState } from "react";
import Navigation from "../components/Navigation/Navigation";
import http from "../utils/Api";
import { useParams } from "react-router-dom";

const PlayerAssignedTasks = () => {
  const { id } = useParams();
  const [sideMenu, setSideMenu] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllAssignedTasks();
  }, []);

  const getAllAssignedTasks = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await http.get(`/api/admin/getAssignedTasks/${id}`, http.generateConfig(token));
      console.log(response, "the response here");
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error("Error fetching assigned tasks", error);
    }
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "pending":
        return "badge bg-warning text-dark";
      case "completed":
        return "badge bg-success";
      case "in-progress":
        return "badge bg-primary";
      default:
        return "badge bg-secondary";
    }
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}
      >
        <div className="container py-4">
          <h2 className="text-center mb-4">Assigned Tasks</h2>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-primary">
                <tr>
                  <th>Status</th>
                  <th>Actions</th>
                  <th>Reward Amount</th>
                  <th>Completed On</th>
                  <th>Task Description</th>
                  <th>Reward Text</th>
                  <th>URL</th>
                  <th>Expires At</th>
                  <th>Social Platform</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((task, index) => (
                    <tr key={index}>
                      <td>
                        <span className={getStatusClass(task.status)}>{task.status}</span>
                      </td>
                      <td>{task.actions.join(", ")}</td>
                      <td>{task.rewardAmount}</td>
                      <td>{task.completedOn ? new Date(task.completedOn).toLocaleString() : "N/A"}</td>
                      <td>{task.AmbassadorTask?.description}</td>
                      <td>{task.AmbassadorTask?.rewardText}</td>
                      <td>
                        <a
                          href={task.AmbassadorTask?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none text-primary"
                        >
                          Visit Link
                        </a>
                      </td>
                      <td>
                        {task.AmbassadorTask?.expiresAt
                          ? new Date(task.AmbassadorTask.expiresAt).toLocaleString()
                          : "N/A"}
                      </td>
                      <td>{task.AmbassadorTask?.SocialPlatform?.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No tasks assigned.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerAssignedTasks;
