import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteDeviceModal = ({ show, onHide, message, onDelete }) => {
  return (
    <Modal show={show} onHide={onHide} style={{ top: "200px" }}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Device Deletion</Modal.Title>
      </Modal.Header>
     <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteDeviceModal;
