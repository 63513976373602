import {
  addUsers,
  getUser,
  getAllUsers,
  updateUsers,
  removeUser,
  clearUserDevice,
  sus,
  notSuspicious,
  deleteUserAccount
} from "../actionTypes/types";

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case addUsers:
      // Assuming action.payload contains a single user object
      return [...state, action.payload];
      case getUser:
      // Find and return the user with the given ID
      return action.payload;
      case getAllUsers:
        // Assuming action.payload contains an array of user objects
        return action.payload;
    case updateUsers:
        console.log(action.payload,'the action payload')
      // Find the index of the user with the matching ID
      return state.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    case clearUserDevice:
      return state.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    case removeUser:
      // Remove the user with the given ID from the state
      return state.filter((user) => user.id !== action.payload.id);
    case sus:
      return state.map((user) =>
        user.id === action.payload ? { ...user, isSuspicious: true } : user
      );
    case notSuspicious:
      return state.map((user) =>
        user.id === action.payload ? { ...user, isSuspicious: false } : user
      );
    case deleteUserAccount:
      return state.map((user) => 
      user.id === action.payload ? { ...user, accountDeleted : true} : user);
      
    default:
      return state;
  }
};

export default usersReducer;
