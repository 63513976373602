import React, { useState } from "react";
import Navigation from "../components/Navigation/Navigation";
import { Form, Button, Spinner, Card } from "react-bootstrap";
import Footer from "./Footer/Footer";
import http from "../utils/Api";
import { toast } from "react-toastify";
import * as Icon from "react-feather";
import CircularProgressWithLabel from "../components/common/CircularProgress";
const WalletExplore = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const accessToken = localStorage.getItem("accessToken");

  const handleExamine = async () => {
    if (!walletAddress) {
      toast.error("Please enter wallet address to inspect");
      return;
    }

    setLoading(true);

    try {
      const res = await http.post(
        `/api/admin/getThirdWaveWallet`,
        {
          wallet: walletAddress,
        },
        http.generateConfig(accessToken)
      );
      setWalletData(res.data.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const iconMapping = {
    address: <Icon.Feather size={16} style={{ marginRight: "8px" }} />,
    balance: <Icon.DollarSign size={16} style={{ marginRight: "8px" }} />,
    botWarning: <Icon.AlertTriangle size={16} style={{ marginRight: "8px" }} />,
    outboundTransactionCount: (
      <Icon.TrendingUp size={16} style={{ marginRight: "8px" }} />
    ),
    outboundTransactionValue: (
      <Icon.DollarSign size={16} style={{ marginRight: "8px" }} />
    ),
    firstSeenAt: <Icon.Calendar size={16} style={{ marginRight: "8px" }} />,
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        <div className="container mt-4" style={{ marginLeft: "18px" }}>
          <h4 style={{ textAlign: "left" }}>Examine Wallet</h4>
          <div className="d-flex flex-column flex-md-row justify-content-start align-items-start">
            <div className="me-3 mb-2 mb-md-0">
              {" "}
              {/* Change to flex-grow-1 */}
              <Form.Control
                type="text"
                placeholder="Enter wallet address to inspect"
                value={walletAddress}
                style={{ padding: "10px", width: "300px" }}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
            </div>
            <div className="d-flex flex-row justify-content-start align-items-start">
              <Button
                variant="primary"
                onClick={handleExamine}
                disabled={loading}
                style={{ height: "38px" }} // Align button with input height
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Examine"}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setWalletData(null); // Reset walletData to null
                  setWalletAddress(""); // Optionally clear the input field
                }}
                style={{ height: "38px", marginLeft: "10px" }} // Align button with input height
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="container my-4 d-flex justify-content-center align-items-center"
            // style={{ height: "100vh" }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: "3rem", height: "3rem", borderWidth: "0.3rem" }} // Custom size and thickness
            >
              {/* <span className="sr-only">Loading...</span> */}
            </Spinner>
          </div>
        ) : (
          <div className="container my-4">
            {walletData && (
              <>
                <div className="container my-4">
                  <div
                    className="row justify-content-between mb-4 g-2"
                    style={{ width: "100%" }}
                  >
                    {[
                      { key: "address", label: "Wallet Address" },
                      { key: "balance", label: "Balance" },
                      { key: "botWarning", label: "Bot Warning" },
                    ].map((field, index) => (
                      <div
                        key={index}
                        className={`col-12 ${
                          field.key === "address"
                            ? "col-md-6 col-lg-5"
                            : field.key === "botWarning"
                            ? "col-md-3 col-lg-4"
                            : "col-md-3 col-lg-3"
                        }  mb-3`}
                      >
                        <Card
                          className="p-3 my-2 shadow-sm"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            transition: "transform 0.2s, box-shadow 0.2s",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                            e.currentTarget.style.boxShadow =
                              "0 8px 16px rgba(0, 0, 0, 0.2)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                            e.currentTarget.style.boxShadow =
                              "0 4px 8px rgba(0, 0, 0, 0.1)";
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "5px",
                            }}
                          >
                            {iconMapping[field.key]}{" "}
                            <h6
                              style={{
                                margin: 0,
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              {field.label}
                            </h6>
                          </div>
                          {field.key === "botWarning" ? (
                            <p
                              style={{
                                fontWeight: "900",
                                marginTop: "4px",
                                color: `${
                                  walletData.botWarning ? "red" : "green"
                                }`,
                                fontSize: "16px",
                                marginBottom: "0px",
                              }}
                            >
                              {walletData.botWarning ? "True" : "False"}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontWeight: "900",
                                marginTop: "4px",
                                fontSize: `${
                                  field.key === "address" ? "12px" : "16px"
                                }`,
                                marginBottom: "0px",
                              }}
                            >
                              {walletData[field.key]}
                            </p>
                          )}
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="container my-4">
                  <div className="row justify-content-between mb-4">
                    {[
                      {
                        key: "outboundTransactionCount",
                        label: "Out Bound Transaction Count",
                      },
                      {
                        key: "outboundTransactionValue",
                        label: "Out Bound Transaction Value",
                      },
                      {
                        key: "firstSeenAt",
                        label: "First Seen",
                      },
                    ].map((field, index) => (
                      <div
                        className={`col-12 ${
                          field.key !== "firstSeenAt" ? "col-md-4" : "col-md-3"
                        }   col-lg-4 mb-3`}
                        key={index}
                      >
                        <Card
                          className="p-3 my-2 shadow-sm"
                          style={{
                            borderRadius: "15px",
                            cursor: "pointer",
                            transition: "transform 0.2s, box-shadow 0.2s",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                            e.currentTarget.style.boxShadow =
                              "0 8px 16px rgba(0, 0, 0, 0.2)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                            e.currentTarget.style.boxShadow =
                              "0 4px 8px rgba(0, 0, 0, 0.1)";
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "5px",
                            }}
                          >
                            {iconMapping[field.key]}{" "}
                            <h6
                              style={{
                                margin: 0,
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              {field.label}
                            </h6>
                          </div>
                          {field.key === "firstSeenAt" ? (
                            <p
                              style={{
                                fontWeight: "900",
                                fontSize: "16px",
                                marginTop: "4px",
                                marginBottom: "0px",
                              }}
                            >
                              {walletData[field.key].split("T")[0]}{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontWeight: "900",
                                marginTop: "4px",
                                fontSize: "16px",
                                marginBottom: "0px",
                              }}
                            >
                              {walletData[field.key]}
                            </p>
                          )}
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="container my-4">
                  <div className="row g-4 justify-content-center">
                    {" "}
                    <div className="col-12 col-md-6">
                      {" "}
                      <Card
                        className="p-3 mb-2 shadow-sm"
                        style={{
                          borderRadius: "15px",
                          width: "100%",
                        }}
                      >
                        <h5>Transaction Pattern</h5>
                        <div className="d-flex flex-column">
                          {walletData?.transactionPatterns?.map(
                            (pattern, index) => (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center border p-2 my-1"
                                style={{
                                  borderRadius: "8px",
                                  backgroundColor: "#f8f9fa", // Light background for better visibility
                                }}
                              >
                                {/* Larger font on medium and up */}
                                <strong className="fs-6 d-none d-md-block">
                                  {pattern?.key}
                                </strong>{" "}
                                {/* Smaller font on small devices */}
                                <strong
                                  style={{ fontSize: "10px" }}
                                  className=" d-md-none"
                                >
                                  {pattern?.key}
                                </strong>{" "}
                                <span
                                  style={{
                                    color: pattern?.value ? "red" : "green",
                                    fontWeight: "700",
                                  }}
                                >
                                  {pattern?.value ? "True" : "False"}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </Card>
                    </div>
                    <div className="col-12 col-md-6 pb-1">
                      {" "}
                      <Card
                        className="p-3 mb-4 shadow-sm"
                        style={{ borderRadius: "15px", width: "100%" }}
                      >
                        <h5 className="mb-1">Activity Scores</h5>
                        <div className="d-flex justify-content-around">
                          {" "}
                          <div className="my-2 mb-4 text-center">
                            <h6>Engagement Score</h6>
                            <CircularProgressWithLabel
                              value={walletData?.engagementScore}
                              max={100}
                              label={`${walletData?.engagementScore}/100`}
                            />
                          </div>
                          <div className="my-2 mb-4 text-center">
                            <h6>Hodler Score</h6>
                            <CircularProgressWithLabel
                              value={walletData?.hodlerScore}
                              max={100}
                              label={`${walletData?.hodlerScore}/100`}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default WalletExplore;
