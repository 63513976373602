import { GET_ALL_GAMES_OF_MONTH, ADD_GAME_OF_MONTH_REWARD,SEARCH_GAME_BY_NAME  } from "../actionTypes/types";

const initialState = {
  games: [],
  searchResults: [], // Add search results to state
};

const gameOfMonthReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GAMES_OF_MONTH:
      return {
        ...state,
        games: action.payload,
      };
    case SEARCH_GAME_BY_NAME:
      return {
        ...state,
        searchResults: action.payload, // Store search results in state
      };
    case ADD_GAME_OF_MONTH_REWARD: {
      const { g_id, rewards } = action.payload;
      return {
        ...state,
        games: state.games.map((game) =>
          game.id === g_id
            ? {
                ...game,
                GameOfMonthRewards: [...game.GameOfMonthRewards, ...rewards],
              }
            : game
        ),
      };
    }
    default:
      return state;
  }
};

export default gameOfMonthReducer;