import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { searchGameByName } from "../../service/actions/gameOfMonthActions";

const SearchRewardsModal = ({ show, handleClose, handleAddReward }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGame, setSelectedGame] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [rewardsData, setRewardsData] = useState([
    { rank: "1", rewardType: "Points", amount: "1000" },
    { rank: "2", rewardType: "Points", amount: "750" },
    { rank: "3", rewardType: "Points", amount: "500" },
    { rank: "4", rewardType: "Points", amount: "300" },
    { rank: "5", rewardType: "Points", amount: "250" },
  ]);
  const [editingIndex, setEditingIndex] = useState(null); // Track which row is being edited

  const dispatch = useDispatch();

  const searchResults = useSelector((state) => state.gameOfMonth.searchResults);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowDropdown(query.trim() !== ""); // Show dropdown when there is text in search field

    if (query.trim() === "") {
      setSelectedGame(null);
    }

    // Dispatch the search action when the user types
    dispatch(searchGameByName(query));
  };

  const handleSelectGame = (game) => {
    setSelectedGame(game);
    console.log("Selected game:", game);  
    setSearchQuery(game.name); // Set the search query to the selected game's name
    setShowDropdown(false); // Close the dropdown after selection
  };

  const handleAmountChange = (index, value) => {
    const updatedRewards = [...rewardsData];
    updatedRewards[index].amount = value;

    // Sort rewards data by amount in descending order
    updatedRewards.sort((a, b) => Number(b.amount) - Number(a.amount));

    // Recalculate the rank after sorting
    updatedRewards.forEach((reward, i) => {
      reward.rank = (i + 1).toString(); // Adjust rank based on the sorted order
    });

    setRewardsData(updatedRewards);
    setEditingIndex(index); // Track which field was edited to keep the cursor on it
  };

  const handleAdd = () => {
    if (!selectedGame) {
      console.error("No game selected.");
      return;
    }

    const isDataValid = rewardsData.every(
      (reward) => reward.amount.trim() !== "" && !isNaN(reward.amount)
    );
    if (!isDataValid) {
      console.error("All fields must be filled and valid.");
      return;
    }

    const payload = {
      g_id: selectedGame.id,
      rewards: rewardsData.map(({ rank, rewardType, amount }) => ({
        rank,
        rewardType,
        amount: Number(amount),
      })),
    };

    console.log("Payload to be sent:", payload); // Debugging log

    // Call parent handler
    handleAddReward(payload); // Ensure the payload matches the expected format in the parent component
    resetModalState();
    handleClose();
  };

  const resetModalState = () => {
    setSearchQuery("");
    setSelectedGame(null);
    setRewardsData([
      { rank: "1", rewardType: "Points", amount: "1000" },
      { rank: "2", rewardType: "Points", amount: "750" },
      { rank: "3", rewardType: "Points", amount: "500" },
      { rank: "4", rewardType: "Points", amount: "300" },
      { rank: "5", rewardType: "Points", amount: "250" },
    ]);
    setShowDropdown(false); // Reset dropdown visibility
    setEditingIndex(null); // Reset the editing index
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ marginTop: "80px" }}>
      <Modal.Header closeButton>
        <Modal.Title>Add Reward Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSearchGame">
            <Form.Label>Search Game</Form.Label>
            <div style={{ position: 'relative' }}>
              {/* Input field showing the game name and icon when selected */}
              <Form.Control
                type="text"
                placeholder="Search by game name"
                value={selectedGame ? selectedGame.name : searchQuery} // Show game name if selected, otherwise search query
                onChange={handleSearchChange}
                style={{
                  marginBottom: "10px",
                  paddingLeft: selectedGame ? "40px" : "10px",
                  fontSize: '14px' // Adjust padding for icon when game is selected
                }}
              />
              
              {selectedGame && (
                <div
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={selectedGame.game_icon}
                    alt={selectedGame.name}
                    style={{
                      width: "25px", // Icon size
                      height: "25px", // Icon size
                      borderRadius: "10%", // Border radius for the icon
                      marginRight: "10px", // Increased space between icon and text
                    }}
                  />
                </div>
              )}
            </div>
          </Form.Group>

          {showDropdown && searchQuery && searchResults && searchResults.length > 0 && (
            <div
              style={{
                maxHeight: "200px", 
                overflowY: "auto", 
                border: "1px solid #ddd", 
                padding: "5px",
                borderRadius: "5px", 
              }}
            >
              {searchResults.map((game) => {
                if (game && game.id && game.name) {
                  return (
                    <div
                      key={game.id}
                      onClick={() => handleSelectGame(game)}
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        borderBottom: "1px solid #ddd",
                        display: "flex",
                        alignItems: "center", // Align icon and text
                      }}
                    >
                      {/* Add the game icon */}
                      {game.game_icon && (
                        <img
                          src={game.game_icon}
                          alt={game.name}
                          style={{
                            width: "20px", // Icon size
                            height: "20px", // Icon size
                            borderRadius: "10%", // Border radius for the icon
                            marginRight: "10px", // Space between icon and text
                          }}
                        />
                      )}
                      {game.name}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </Form>

        {selectedGame && (
          <div>
            <h5>Rewards for {selectedGame.name}</h5>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Reward Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {rewardsData.map((reward, index) => (
                  <tr key={index}>
                    <td>{reward.rank}</td>
                    <td>{reward.rewardType}</td>
                    <td>
                      <Form.Control
                        type="number"
                        value={reward.amount}
                        onChange={(e) => handleAmountChange(index, e.target.value)}
                        autoFocus={index === editingIndex} // Keep the cursor on the edited field
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAdd} disabled={!selectedGame}>
          Add Data
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchRewardsModal;
