import {
    GET_ALL_AMBASSADORS_TASK,
    DELETE_AMBASSADOR_TASK,
    ADD_AMBASSADOR_TASK,
    UPDATE_AMBASSADOR_TASK,
} from "../actionTypes/types";

const initialState = {
    ambassadors: [],
    loading: true,
};

const ambassadorTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_AMBASSADORS_TASK:
            return {
                ...state,
                ambassadors: action.payload,
                loading: false,
            };
        case ADD_AMBASSADOR_TASK:
            return {
                ...state,
                ambassadors: [...state.ambassadors, action.payload],
            };
        case DELETE_AMBASSADOR_TASK:
            return {
                ...state,
                ambassadors: state.ambassadors.filter(
                    ambassador => ambassador.id !== action.payload.id
                ),
            };
        case UPDATE_AMBASSADOR_TASK:
            return {
                ...state,
                ambassadors: state.ambassadors.map(ambassador =>
                    ambassador.id === action.payload.id
                        ? { ...ambassador, ...action.payload }
                        : ambassador
                ),
            };
        default:
            return state;
    }
};

export default ambassadorTaskReducer;
