import { combineReducers } from "redux";
import authReducer from "./authReducer";
import gameReducer from "./gameReducer";
import usersReducer from "./usersReducer";
import gameStudioReducer from "./gameStudioReducer";
import usersPermissionReducer from "./usersPermissionReducer";
import userProfileReducer from "./userProfileReducer";
import rewardReducer from "./rewardRedicer";
import taskReducer from "./taskReducer";
import deviceReducer from "./deviceReducer";
import blockedDeviceReducer from "./blockedDeviceReducer";
import gameOfMonthReducer from "./gameOfMonthReducer";
import ambassadorReducer from './AmbassadorReducer'
import ambassadorTaskReducer from "./AmbassadorTaskReducer";
const rootReducer = combineReducers({
  auth: authReducer,
  game: gameReducer,
  users: usersReducer,
  gameStudio: gameStudioReducer,
  userPermission: usersPermissionReducer,
  userProfile: userProfileReducer,
  reward:rewardReducer,
  task:taskReducer,
  device: deviceReducer,
  blockedDevice: blockedDeviceReducer,
  gameOfMonth: gameOfMonthReducer,
  ambassador: ambassadorReducer,
  ambassadorTask: ambassadorTaskReducer
});

export default rootReducer;
