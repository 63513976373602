import http from "../../utils/Api";
import {
  addUsers,
  getUser,
  getAllUsers,
  updateUsers,
  removeUser,
  getAllUsersPermission,
  clearUserDevice,
  sus,
  notSuspicious,
  deleteUserAccount
} from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
const getStudioUsers = (tab, page = 1, limit = 10, searchParams = {}) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    // Construct query parameters dynamically for mobile, email, deviceId, and playerId
    const query = new URLSearchParams({
      page,
      limit,
      ...searchParams, 
    }).toString();

    let res;

    if (tab === "normal") {
      res = await http.get(`/api/admin/getAllPlayers?${query}`, http.generateConfig(token));
    } else {
      res = await http.get(`/api/admin/getAllLockedPlayers?${query}`, http.generateConfig(token));
    }

    
    if (res) {
      const { result } = res; 
      dispatch({
        type: "getAllUsers",
        payload: result, // The list of users
      });
    }
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};


const getUsersPermission = () => async (dispatch) => {
  try {
    let { id, role_name } = JSON.parse(localStorage.getItem("userRoles"));
    const res = await http.get(`/api/getRolePermissions/${id}`);
    if (res) {
      dispatch({
        type: getAllUsersPermission,
        payload: res.result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getSingleuser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data; //game ID
    const res = await http.get(
      `/api/game/${id}`,
      data,
      http.generateConfig(token)
    );
    dispatch({
      type: getUser,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};

const addUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/createUserWithRole`,
      { ...data, gameStudioId: id },
      http.generateConfig(token)
    );

    if (dat) {
      dispatch({
        type: addUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const clearUserDeviceA = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");


    const dat = await http.post(
      `/api/admin/clearPlayerDevice`,
      data,
      http.generateConfig(token)
    );
    if (dat) {
      dispatch({
        type: clearUserDevice,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
const updateUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data;
    const dat = await http.post(
      `/api/admin/blockPlayerById/`,
      {pid:id},
      http.generateConfig(token)
    );
    if (dat) {
      dispatch({
        type: updateUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const deleteUser = (data) => async (dispatch) => {
  try {
    const { id } = data; //game ID
    const token = localStorage.getItem("accessToken");
    let { tid } = jwt_decode(token);
    const dat = await http.post(
      `/api/deleteUser`,
      {
        userId: data,
        gameStudioId: id,
      },
      http.generateConfig(token)
    );
    dispatch({
      type: removeUser,
      payload: dat?.result,
    });
    toast(dat.message, dat.success);
    console.log("anything")
  } catch (err) {
    console.log(err);
  }
};

const getViralMarketingData = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    console.log(payload, "the body");
    const response = await http.post(
      "api/getBoostLogs",
      payload,
      http.generateConfig(token)
    );
    return response;
  } catch (err) {
    console.log("err:", err);
  }
};
const getAllPaidTiers = async () => {
  try {
    const response = await http.get("api/getAllPaidTiers");
    return response;
  } catch (err) {
    console.log("err:", err);
  }
};
const markAsSus = (id,susReason) => async(dispatch) => {
  try {

    
    const token = localStorage.getItem("accessToken");
    console.log(token,'HTE ACCESS TOKEN')
    const config = http.generateConfig(token);
    console.log(config,"THE ON")
    const response = await http.post(
      '/api/admin/markPlayerSus',
      { id ,susReason},
      config
    );
    dispatch({
      type: sus, 
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
}

export const kycAccount =(id, flag) => async (dispatch)=>{
  try{
    const token = localStorage.getItem("accessToken");
    const response = await http.put(
      '/api/admin/updateKycStatus',
      { p_id:id ,kycDone:flag},
      http.generateConfig(token)
    );

    console.log(response,'ther eaopsid')
    dispatch({
      type: updateUsers, 
      payload: {...response.result, kycDone:flag} ,
    });
  }
  catch(err){
    console.error(err);
  }
}

export const deleteAccount = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await http.post(
      '/api/admin/deleteUser',
      { id },
      http.generateConfig(token)
    );

    dispatch({
      type: deleteUserAccount, 
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

const unMarkAsSus = (id,susReason) => async(dispatch) => {
  try {

    
    const token = localStorage.getItem("accessToken");
    console.log(token,'HTE ACCESS TOKEN')
    const config = http.generateConfig(token);
    console.log(config,"THE ON")
    const response = await http.post(
      '/api/admin/unMarkPlayerSus',
      { id , susReason },
      config
    );
    dispatch({
      type: notSuspicious, 
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
}
export {
  getSingleuser,
  getStudioUsers,
  addUser,
  updateUser,
  deleteUser,
  getViralMarketingData,
  getAllPaidTiers,
  getUsersPermission,
  clearUserDeviceA,
  markAsSus,
  unMarkAsSus
};