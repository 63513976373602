import http from "../../utils/Api";
import { GET_ALL_GAMES_OF_MONTH, ADD_GAME_OF_MONTH_REWARD, SEARCH_GAME_BY_NAME } from "../actionTypes/types";

const accessToken = localStorage.getItem("accessToken");

const getAllGamesOfMonth = () => async (dispatch) => {
  try {
    
    const response = await http.get(
      "/api/admin/getAllGameOfMonthWithRewards",
      http.generateConfig(accessToken)
    );
    console.log('Api Response', response)

    if (response && response?.games) {
      dispatch({
        type: GET_ALL_GAMES_OF_MONTH,
        payload: response.games,
      });
    } else {
      console.warn("No games found in the response.");
    }
  } catch (error) {
    console.error("Error fetching Game of the Month data:", error);
  }
};

const addGameOfMonthReward = (g_id, rewards) => async (dispatch) => {
  try {
    const payload = {
      g_id,
      rewards: rewards.map(({ rank, rewardType, amount }) => ({
        rank,
        rewardType,
        amount,
      })),
    };

    const response = await http.post(
      "/api/admin/addGameOfMonth",
      payload,
      http.generateConfig(accessToken)
    );

    console.log("API Response:", response); // Log the response for debugging

    if (response && response.status === 'success') {
      dispatch({
        type: ADD_GAME_OF_MONTH_REWARD,
        payload: { g_id, rewards },
      });
    } else {
      console.warn("Failed to add rewards:", response?.message || "Unknown error");
    }

    return response;  // Return the response here
  } catch (error) {
    console.error("Error adding rewards:", error);
    return null; // Return null in case of error
  }
};


const searchGameByName = (gameName) => async (dispatch) => {
  try {
    const response = await http.get(
      `/api/admin/searchGameByName?gameName=${gameName}`,
      http.generateConfig(localStorage.getItem("accessToken"))
    );
    if (response && response?.data) {
      // Dispatch the search result if games are found
      dispatch({
        type: SEARCH_GAME_BY_NAME,
        payload: response.data,
      });
    } else {
      console.warn("No games found for the search query.");
    }
  } catch (error) {
    console.error("Error searching for game:", error);
  }
};


export { getAllGamesOfMonth, addGameOfMonthReward, searchGameByName };
