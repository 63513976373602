import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState , useEffect } from "react";

const CircularProgressWithLabel = ({ value, max, label }) => {
    const [size, setSize] = useState(150); // Default size
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 576) { // For small devices
          setSize(100); // Smaller width for small devices
        } else if (window.innerWidth < 768) { // For medium devices
          setSize(120); // Slightly larger for medium devices
        } else {
          setSize(150); // Default size for larger devices
        }
      };
  
      // Set initial size
      handleResize();
  
      // Add resize event listener
      window.addEventListener("resize", handleResize);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return (
      <div style={{ width: `${size}px`, margin: "auto" }}>
        <CircularProgressbar
          value={value}
          maxValue={max}
          text={`${label}`}
          styles={buildStyles({
            textColor: "#343a40", // Text color in the center
            pathColor: "#04542D", // Progress bar color
            trailColor: "#e9ecef", // Background trail color
            textSize: "20px", // Font size for the text in the center
          })}
        />
      </div>
    );
  };
  
export default CircularProgressWithLabel