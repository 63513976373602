import http from "../../utils/Api";
import { addAmbassador, deleteAmbassador, getAllAmbassadors, updateUsers } from "../actionTypes/types";




const getAmbassadors = ()=>async (dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");

        const res = await http.get(`/api/admin/getAmbassadors`, http.generateConfig(token));
        if(res){
            dispatch({
                type:getAllAmbassadors,
                payload:res.data,
            })
        }
    }
    catch(error){
        console.log(error)
    }
}

const removeAmbassador = (id)=>async(dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");

        const res = await http.post(`/api/admin/removeAmbassador`,{userId:id},http.generateConfig(token));
        if(res){
            dispatch({
                type:deleteAmbassador,
                payload:res.data
            });
            dispatch({
                type:updateUsers,
                payload:res.data
            })
        }
    }
    catch(error){
        console.log(error)
    }
}


const MarkAmbassador =(id)=> async (dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");

        const res = await http.post(`/api/admin/addAmbassador`,{id:id},http.generateConfig(token));
        if(res){
            dispatch({
                type:addAmbassador,
                payload:res.data
            });
            dispatch({
                type:updateUsers,
                payload:res.data
            })
        }
    }catch(error){
        console.log(error)
    }
}

export {
    getAmbassadors,
    removeAmbassador,
    MarkAmbassador
}