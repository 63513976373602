/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

// import PrivateRoute from './Auth/privateRoutes'
import { createBrowserHistory } from "history";

import { ActiveUsers, AddGame, AddUser, GamePage } from "./pages";

// import { socket } from './socket';

import ForgetPassword from "./Auth/ForgetPassword";
import ForgetPasswordAuth from "./Auth/ForgetPasswordAuth";
import Suspended from "./Auth/Suspended";
import Login from "./Auth/login";
import PrivateRoute from "./Auth/privateRoutes";
import Signup from "./Auth/signup";
import Dashboard from "./pages/Dashboard";
import User from "./pages/User";
import NotFound from "./pages/NotFound";
import Reward from "./pages/Reward";
import GameStudio from "./pages/GameStudio";
import GameProfilePage from "./pages/GameProfilePage";
import Activity from "./pages/Activity";
import ActivityDetail from "./pages/ActivityDetail";
import Security from "./pages/Security";
import Impression from "./pages/impressions";
import Fraud from "./pages/fraud";
import Record from "./pages/record";
import MessageOfTheDay from "./pages/message";
import FailedAttempts from "./pages/failedLogin";
import SystemVariables from "./pages/systemVariables";
import WeeklyTopPlayers from "./pages/WeelyTopPlayers";
import Tasks from "./pages/Tasks";
import DeviceInformation from "./pages/DeviceInformation";
import FraudRating from "./pages/FraudRating";
import PlayerRiskFactor from "./pages/PlayerRiskFactor";
import WalletExplore from "./pages/WalletExplore";
import PlayerEMa from "./pages/PlayerEma";
import NewPage from "./pages/BlockedDevices";
import BlockedDevices from "./pages/BlockedDevices";
import AdminLogin from "./pages/adminLogin";
import GameOfMonthRewards from "./pages/GameOfMonthRewards";
import Ambassador from "./pages/Ambassador";
import AddTask from "./pages/addTask";
import PlayerAssignedTasks from './pages/PlayerAssignedTasks'
// import { useState } from "react";
function App() {
  const history = createBrowserHistory();

  // const [isConnected, setIsConnected] = useState(socket.connected);

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          {/* <Route path="*" elememnt={<NotFound />} /> */}
          <Route
            path="/game"
            element={
              <PrivateRoute>
                <GamePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/notify"
            element={
              <PrivateRoute>
                <MessageOfTheDay />
              </PrivateRoute>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          />
          <Route
            path="/Ambassador"
            element={
              <PrivateRoute>
                <Ambassador />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/:id/risk-factor"
            element={
              <PrivateRoute>
                <PlayerRiskFactor />
              </PrivateRoute>
            }
          />
          <Route
            path="/gameStudio"
            element={
              <PrivateRoute>
                <GameStudio />
              </PrivateRoute>
            }
          />
          <Route
            path="/reward"
            element={
              <PrivateRoute>
                <Reward />
              </PrivateRoute>
            }
          />
          <Route
            path="/adminLogin"
            element={
              <PrivateRoute>
                <AdminLogin />
              </PrivateRoute>
            }
          />
          <Route
            path="/security"
            element={
              <PrivateRoute>
                <Security />
              </PrivateRoute>
            }
          />
          <Route
            path="/addGame"
            element={
              <PrivateRoute>
                <AddGame />
              </PrivateRoute>
            }
          />
          <Route
            path="/device"
            element={
              <PrivateRoute>
                <DeviceInformation />
              </PrivateRoute>
            }
          />
          <Route
            path="/gameProfile/:id"
            element={
              <PrivateRoute>
                <GameProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/addUser"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/inspect-wallet"
            element={
              <PrivateRoute>
                <WalletExplore />
              </PrivateRoute>
            }
          />

          <Route
            path="/activeUsers"
            element={
              <PrivateRoute>
                <ActiveUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/impressions"
            element={
              <PrivateRoute>
                <Impression />
              </PrivateRoute>
            }
          />
          <Route
            path="/fraud"
            element={
              <PrivateRoute>
                <Fraud />
              </PrivateRoute>
            }
          />
          <Route
            path="/fraud-rating"
            element={
              <PrivateRoute>
                <FraudRating />
              </PrivateRoute>
            }
          />
          <Route
            path="/record"
            element={
              <PrivateRoute>
                <Record />
              </PrivateRoute>
            }
          />
          <Route
            path={`/Tasks`}
            element={
              <PrivateRoute>
                <Tasks />
              </PrivateRoute>
            }
          />
          <Route
            path={`/blocked-devices`}
            element={
              <PrivateRoute>
                <BlockedDevices />
              </PrivateRoute>
            }
          />

        <Route
            path={`/add-task`}
            element={
              <PrivateRoute>
                <AddTask />
              </PrivateRoute>
            }
          />

          <Route
            path={`/game-of-month-with-rewards`}
            element={
              <PrivateRoute>
                <GameOfMonthRewards />
              </PrivateRoute>
            }
          />
          <Route
            path="/forget-password"
            element={
              // <PrivateRoute>
              <ForgetPassword />
              // </PrivateRoute>
            }
          />
          <Route
            path={`/create-new-password/:paramsToken`}
            element={
              // <PrivateRoute>
              <ForgetPasswordAuth />
              // </PrivateRoute>
            }
          />

          <Route
            path="/failedLogin"
            element={
              <PrivateRoute>
                <FailedAttempts />
              </PrivateRoute>
            }
          />

          <Route
            path={`/suspended`}
            element={
              // <PrivateRoute>
              <Suspended />
              // </PrivateRoute>
            }
          />
          <Route
            path={"/playerEMA/:id"}
            element={
              <PrivateRoute>
                <PlayerEMa />
              </PrivateRoute>
            }
          />
          <Route
            path={"/playerAssignedTasks/:id"}
            element={
              <PrivateRoute>
                <PlayerAssignedTasks />
              </PrivateRoute>
            }
          />

          <Route path={"/activity"} element={<Activity />} />
          <Route path={"/activitydetail/:id"} element={<ActivityDetail />} />
          <Route
            path="*"
            element={
              // <PublicRoute>
              <NotFound />
              // </PublicRoute>
            }
          />
          <Route path={"/systemVariables"} element={<SystemVariables />} />

          <Route path={"/topPlayers"} element={<WeeklyTopPlayers />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
