import http from "../../utils/Api";

const accessToken = localStorage.getItem("accessToken");

// Fetch all blocked devices and dispatch to Redux store
const getBlockedDevices = async () => {
  try {
    const response = await http.get("/api/admin/getAllBlackListDevices", http.generateConfig(accessToken));

    if (response) {
      return response;  
    } else {
      console.warn("No devices found in the response.");
      return [];  // Return an empty array if no devices
    }
  } catch (error) {
    console.error("Error fetching blocked devices:", error);
    return [];  // Return an empty array on error
  }
};


// Update a blocked device by its ID
const updateBlockedDeviceAction = (data, id) => async (dispatch) => {
  try {
    console.log("Updating blocked device data-->", data);
    const response = await http.put(
      `/api/admin/blockDevice/${id}`,
      data,
      http.generateConfig(accessToken)
    );

    if (response) {
      return response;  
    } else {
      console.warn("No devices found in the response.");
      return [];  
    }
  } catch (error) {
    console.error("Error updating blocked devices:", error);
    return [];  
  }
};

// Add a blocked device
const addBlockedDeviceAction = async (deviceId) => {
  try {
const response = await http.post(
  "/api/admin/addBlockDevice",
  { deviceId },
  http.generateConfig(accessToken)
  
);
    if (response) {
      return response;
    } else {
      console.warn("No response from the API.");
      return null;
    }
  } catch (error) {
    console.error("Error adding blocked device:", error);
    return null;
  }
};


// Delete a blocked device by its ID
const deleteBlockedDeviceAction = (deviceId) => async () => {
  try {
    const response = await http.delete(
      `/api/admin/blockDevice/${deviceId}`,
      http.generateConfig(accessToken)
    );

    if (response) {
      console.log("Device deleted successfully!");
    } else {
      console.warn("No response from the API.");
    }
  } catch (error) {
    console.error("Error deleting blocked device:", error);
  }
};




export { getBlockedDevices, updateBlockedDeviceAction, addBlockedDeviceAction, deleteBlockedDeviceAction };
