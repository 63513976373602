import React, { useEffect, useState } from "react";

import { Breadcrumb, Col, Row, Spinner, Table } from "react-bootstrap";

import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import http from "../utils/Api";
import { useLocation } from "react-router-dom";

const columns = [
    { name: "ID", minWidth: "60px", key: "id" },
    { name: "Name", minWidth: "100px", key: "name" },
    { name: "Impact Value", minWidth: "120px", key: "impactValue" },
    { name: "Date", minWidth: "120px", key: "createdAt" },
  ];

const PlayerRiskFactor = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [playerFactor, setPlayerFactor] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const fetchRiskFactor = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        `/api/admin/getPlayerRiskFactors/${id}`,
        http.generateConfig(accessToken) // Ensure accessToken is available in your context
      );
      if(res && res.data.PlayerRiskFactors){
        console.log("res" , res.data.PlayerRiskFactors)
        setPlayerFactor(res.data.PlayerRiskFactors)
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRiskFactor()
  }, []);

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Player Risk Factor</h1>
          </Breadcrumb>
        </div>

        <div
          className="max-width-container"
          style={{ width: "100%", maxWidth: "99%", margin: "0 auto" }}
        >
          <Row>
            <Col md={12}>
            <div className="game-list">
                <div className="form-content-adduser container-fluid">
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    playerFactor.length > 0 ?
                    <div>
                      <Table
                        className="align-middle"
                        bordered
                        hover
                        responsive
                        style={{
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          maxWidth: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            {columns?.map((column, index) => (
                              <th
                                key={index}
                                style={{
                                  minWidth: `${column.minWidth}`,
                                  maxWidth: "350px",
                                }}
                              >
                                {column.name}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {playerFactor?.map((row, rowIndex) => {
                            return (
                              <tr key={rowIndex}>
                                {columns?.map((column, colIndex) => (
                                  <td
                                    key={colIndex}
                                    className="text-capitalize"
                                  >
                                    { column.key === "createdAt" ?  row?.createdAt.split("T")[0]:
                                     row?.FraudRiskFactor?.[column.key]
                                     }
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    :
                    <duv>
                        Player Risk Factor is 0
                    </duv>
                  )}
                </div>
              </div>
</Col>
          </Row>
        </div>
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default PlayerRiskFactor;
