import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Spinner, Dropdown, DropdownButton, Table } from "react-bootstrap";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import { toast } from "react-toastify";
import * as Icon from "react-feather";
import { addAmbassadorTask, getAmbassadorsTasks, removeAmbassadorTask } from "../service/actions/AmbassadorTaskAction";

const AddTask = () => {
  const dispatch = useDispatch();
  const [postLink, setPostLink] = useState("");
  const [platform, setPlatform] = useState("Select Platform");
  const [description, setDescription] = useState("");
  const [reward, setReward] = useState("");
  const [loading, setLoading] = useState(false);
  const [sideMenu, setSideMenu] = useState(true);

  const { ambassadors, loading: loadingAmbassadors } = useSelector(
    (state) => state.ambassadorTask
  );

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  useEffect(() => {
    dispatch(getAmbassadorsTasks());
  }, [dispatch]);

  const platforms = [
    { name: "X (Twitter)", icon: <Icon.Twitter size={16} /> },
    { name: "LinkedIn", icon: <Icon.Linkedin size={16} /> },
    { name: "Facebook", icon: <Icon.Facebook size={16} /> },
  ];

  const handleAddTask = async () => {
    if (!postLink || !description || !reward) {
      toast.error("All fields are required");
      return;
    }
    if (platform === "Select Platform") {
      toast.error("Please select a platform");
      return;
    }

    setLoading(true);
    try {
      const taskData = { postLink, sp_id: 1, platform, description, rewardText: reward };
      await dispatch(addAmbassadorTask(taskData)); 
      await dispatch(getAmbassadorsTasks()); 

      setPostLink("");
      setPlatform("Select Platform");
      setDescription("");
      setReward("");

    } catch (err) {
      console.error(err);
      toast.error("Failed to add task. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(removeAmbassadorTask(id)); // Dispatch delete action
      toast.success("Task deleted successfully!");
    } catch (err) {
      console.error(err);
      toast.error("Failed to delete task. Please try again.");
    }
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        <div className="container-fluid mt-4">
          <h4>Add Social Media Task</h4>
          <div className="d-flex flex-column gap-3 mb-3">
            <Form.Control
              type="text"
              placeholder="Enter post link"
              value={postLink}
              onChange={(e) => setPostLink(e.target.value)}
            />
            <div className="d-flex gap-3">
              <DropdownButton
                id="dropdown-platform"
                title={platform}
                variant="outline-secondary"
                onSelect={(selected) => setPlatform(selected)}
              >
                {platforms.map((p) => (
                  <Dropdown.Item
                    key={p.name}
                    eventKey={p.name}
                    className="d-flex align-items-center"
                  >
                    {p.icon}
                    <span style={{ marginLeft: "8px" }}>{p.name}</span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Control
                type="text"
                placeholder="Enter reward text"
                value={reward}
                onChange={(e) => setReward(e.target.value)}
              />
            </div>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter task description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button onClick={handleAddTask} disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : "Add Task"}
            </Button>
          </div>

          <div className="mt-4">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Link</th>
                  <th>Description</th>
                  <th>Reward Text</th>
                  <th>Platform</th>
                </tr>
              </thead>
              <tbody>
                {loadingAmbassadors ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <Spinner animation="border" />
                    </td>
                  </tr>
                ) : ambassadors && ambassadors.length > 0 ? (
                  ambassadors.map((task, index) => (
                    <tr key={task.id}>
                      <td>{task.id}</td>
                      <td>
                        <a href={task.url} target="_blank" rel="noopener noreferrer">
                          {task.url}
                        </a>
                      </td>
                      <td>{task.description || "N/A"}</td>
                      <td>{task.rewardText || "N/A"}</td>
                      <td>
                        {task.SocialPlatform?.name || "N/A"}
                        {/* Optional chaining handles null/undefined */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No tasks available.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddTask;
