import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Spinner } from "react-bootstrap";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import { useParams } from "react-router-dom";
import "./app.css";
import http from "../utils/Api";
import GameAnalytics from "../components/gameStatistics";
import { useSelector } from "react-redux";


const GameProfilePage = () => {
  const { id } = useParams();
  
  const games = useSelector((state) => state.game);
  
  const selectedGame = games.find((game) => game.id === parseInt(id));
  const [sideMenu, setSideMenu] = useState(true);
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [gdata, setgdata] = useState("")
  const token = localStorage.getItem("accessToken");

  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };

  useEffect(() => {
    

    
    getGameData();
    

  }, []);

  const getGameData = async () => {
    const token = localStorage.getItem("accessToken");
    const res = await http.get(`api/admin/getgamebyId/${id}`, http.generateConfig(token));
    setgdata(res.packageName);
    setGameData(res);
   

    setLoading(false); // Set loading to false when data is loaded
  };

 

  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}
      >
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            {/* Breadcrumb links can be added here if needed */}
          </Breadcrumb>
        </div>
        <div>
          {loading ? ( // Display a loading spinner while loading
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              {gameData && ( // Add a conditional check to ensure gameData exists
                <Row>
                  <Col md={5}>
                    <div className="stats-card mb-4">
                      <div className="game-details" style={{ width: "78%", margin: "0 auto", fontWeight: 600 }}>
                        <h2 style={{ marginBottom: "15px" }}>{gameData.name}</h2>
                        <img src={gameData.game_icon} alt={gameData.name} style={{ width: "100px", marginBottom: "10px" }} />

                        <p style={{ textAlign: "justify", marginBottom: "10px" }}>{gameData.gameDescription}</p>
                        <p style={{ marginBottom: "10px" }}>Category: {gameData.gameCategory}</p>
                        <p style={{ marginBottom: "10px" }}>Tags: {gameData.gameTags.join(", ")}</p>
                        <p style={{ marginBottom: "10px" }}>Number of Launches: {gameData.no_of_launches}</p>
                        <p style={{ marginBottom: "10px" }}>Number of Installs: {gameData.lastCumulative}</p>
                        <p style={{ marginBottom: "10px" }}>Gold Flakes Earned: {gameData.GoldFlakesEarnedInThisGame}</p>
                        <p style={{ marginBottom: "10px" }}>Total Game Duration: {gameData.totalGameDuration.hours} hours, {gameData.totalGameDuration.minutes} minutes, {gameData.totalGameDuration.seconds} seconds</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className="stats-card mb-4">
                      <GameAnalytics game={gdata} />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default GameProfilePage;