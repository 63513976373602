import { useEffect, useState } from "react";
import Navigation from "../components/Navigation/Navigation";
import http from "../utils/Api";
import { Button, Table } from "react-bootstrap";

const AdminLogin = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [logins, setLogins] = useState([]);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  useEffect(() => {
    getAdminLoginLogs();
  }, []);

  const getAdminLoginLogs = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await http.get(
        `/api/admin/getAdminLoginLogs`,
        http.generateConfig(token)
      );
      if (response && response.data) {
        const loginsWithGeo = await Promise.all(
          response.data.map(async (login) => {
            // Fetch geolocation based on IP address
            const geoData = await getGeolocationByIP(login.geoTags);
            return { ...login, geoData };
          })
        );
        // Reverse the logins array so that the most recent login appears first
        loginsWithGeo.reverse();
        setLogins(loginsWithGeo);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching login logs:", error);
    }
  };

// Function to get geolocation based on IP address
const getGeolocationByIP = async (ip) => {
  try {
    const response = await fetch(`https://get.geojs.io/v1/ip/geo/${ip}.json`);
    if (!response.ok) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = await response.json();
    return {
      city: data.city || "Unknown city",
      region: data.region || "Unknown region",
      country: data.country || "Unknown country",
      lat: data.latitude || "Unknown latitude",
      lon: data.longitude || "Unknown longitude",
    };
  } catch (err) {
    console.error("Error fetching geolocation:", err.message);
    return {
      city: "",
      region: "",
      country: "",
      lat: "",
      lon: "",
    };
  }
};




  // Function to format timestamp in UTC (Date + Time)
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      timeZone: "UTC",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <h3>Logins in Admin Panel</h3>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>IP Address</th>
                <th>Location</th>
                <th>Timestamp</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {logins.map((login) => (
                <tr key={login.id}>
                  <td>{login.id}</td>
                  <td>{login.geoTags}</td>
                  <td>
                    {login?.geoData ? (
                      <>
                        <p>{login?.geoData?.city}</p>
                        <p>{login?.geoData?.region}, {login?.geoData?.country}</p>
                        {/* <p>{login?.geoData?.area}</p> Display area if available */}
                      </>
                    ) : (
                      <p>Location not available</p>
                    )}
                  </td>
                  <td>{formatTimestamp(login.createdAt)}</td> {/* Display formatted timestamp */}
                  <td>
                    <Button variant="danger">Block Ip</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
