import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import "./SideMenu.css";

const SideMenuLight = (props) => {
  return (
    <div
      className={`sidemenu-area sidemenu-light ${
        props.sideMenu ? "" : "sidemenu-toggle"
      }`}
    >
      <Navbar className={`sidemenu ${props.sideMenu ? "" : "hide-nav-title"}`}>
        <Navbar.Collapse>
          <Nav>
            <NavLink to="/home/" className="nav-link">
              <div className="menu-item">
                <Icon.Home className="icon" />
                <span className="title">Home</span>
              </div>
            </NavLink>
            <NavLink to="/adminLogin/" className="nav-link">
              <div className="menu-item">
                <Icon.LogIn className="icon" />
                <span className="title">Logins</span>
              </div>
            </NavLink>
            
            <NavLink to="/game/" className="nav-link">
              <div className="menu-item">
                <Icon.Tv className="icon" />
                <span className="title">Game</span>
              </div>
            </NavLink>
            <NavLink to="/user/" className="nav-link">
              <div className="menu-item">
                <Icon.User className="icon" />
                <span className="title">Users</span>
              </div>
            </NavLink>
            {/* add-task */}
            <NavLink to="/add-task/" className="nav-link">
              <div className="menu-item">
                <Icon.Linkedin className="icon" />
                <span className="title">Ambassadors Tasks</span>
              </div>
            </NavLink>
            <NavLink to="/ambassador/" className="nav-link">
              <div className="menu-item">
                <Icon.Linkedin className="icon" />
                <span className="title">Ambassadors</span>
              </div>
            </NavLink>
            <NavLink to="/tasks/" className="nav-link">
              <div className="menu-item">
                <Icon.BatteryCharging className="icon" />
                <span className="title">Tasks</span>
              </div>
            </NavLink>
            <NavLink to="/activity/" className="nav-link">
              <div className="menu-item">
                <Icon.Activity className="icon" />
                <span className="title">Activity</span>
              </div>
            </NavLink>
            <NavLink to="/gameStudio/" className="nav-link">
              <div className="menu-item">
                <Icon.CreditCard className="icon" />
                <span className="title">gameStudio</span>
              </div>
            </NavLink>
            <NavLink to="/reward/" className="nav-link">
              <div className="menu-item">
                <Icon.DollarSign className="icon" />
                <span className="title">Rewards</span>
              </div>
            </NavLink>
            <NavLink to="/game-of-month-with-rewards" className="nav-link">
              <div className="menu-item">
                <Icon.DollarSign className="icon" />
                <span className="title">Game Of The Month Rewards</span>
              </div>
            </NavLink>
            <NavLink to='/impressions/' className={"nav-link"} >
            <div className="menu-item">
                <Icon.BarChart className="icon" />
                <span className="title">Impression</span>
              </div>
            </NavLink>
            <NavLink to="/notify/" className="nav-link">
              <div className="menu-item">
                <Icon.Mail className="icon" />
                <span className="title">Notify</span>
              </div>
            </NavLink>
            <NavLink to="/security/" className="nav-link">
              <div className="menu-item">
                <Icon.Shield className="icon" />
                <span className="title">Security</span>
              </div>
            </NavLink>
            <NavLink to="/fraud-rating/" className="nav-link">
              <div className="menu-item">
                <Icon.Star className="icon" />
                <span className="title">Fraud Rating</span>
              </div>
            </NavLink>
            <NavLink to="/fraud/" className="nav-link">
              <div className="menu-item">
                <Icon.AlertOctagon className="icon" />
                <span className="title">Fraud</span>
              </div>
            </NavLink>
            <NavLink to="/record/" className="nav-link">
              <div className="menu-item">
                <Icon.AlertOctagon className="icon" />
                <span className="title">Player Records</span>
              </div>
            </NavLink>
            <NavLink to="/device/" className="nav-link">
              <div className="menu-item">
                <Icon.Smartphone className="icon" />
                <span className="title">Device Information</span>
              </div>
            </NavLink>
            <NavLink to="/blocked-devices/" className="nav-link">
              <div className="menu-item">
                <Icon.Smartphone className="icon" />
                <span className="title">Blocked Devices</span>
              </div>
            </NavLink>
            <NavLink to="/activeusers/" className="nav-link">
              <div className="menu-item">
                <Icon.UserCheck className="icon" />
                <span className="title">Active Users</span>
              </div>
            </NavLink>
            <NavLink to="/inspect-wallet/" className="nav-link">
              <div className="menu-item">
                <Icon.Folder className="icon" />
                <span className="title">Examine Wallet</span>
              </div>
            </NavLink>
            <NavLink to="/failedLogin/" className="nav-link">
              <div className="menu-item">
                <Icon.UserX className="icon" />
                <span className="title">Failed Log In Attempts</span>
              </div>
            </NavLink>
            <NavLink to = "/systemVariables/" className="nav-link">
              <div className="menu-item">
              <Icon.Sliders className="icon" />
              <span className="title">System Variables</span>


              </div>

            </NavLink>

            <NavLink to = "/topPlayers/" className="nav-link">
              <div className="menu-item">
              <Icon.Users className="icon" />
              <span className="title">Weekly Top Players</span>


              </div>

            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SideMenuLight;
