const deviceReducer = (state = [], action) => {
    console.log("action.type", action);
    switch (action.type) {
      case "deviceInformation":
        return action.payload
      default:
        return state;
    }
  };
  
  export default deviceReducer;
  