import { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Navigation from "../components/Navigation/Navigation";
import { useParams } from "react-router-dom";
import http from "../utils/Api";
import Chart from "react-apexcharts";

const PlayerEMa = () => {
  const { id } = useParams();
  const [sideMenu, setSideMenu] = useState(true);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Fraud Score",
        data: [],
      },
      {
        name: "EMA Fraud Score",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "line",
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // Ensure y-axis can accommodate both negative and positive values
        labels: {
          formatter: (val) => val.toFixed(3), // Limit y-axis labels to 3 decimal places
        },
        title: {
          text: "Score Values",
        },
      },
      title: {
        text: "Player Fraud and EMA Scores",
        align: "left",
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 5,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (val) => val.toFixed(3), // Format tooltip to 3 decimal places
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels if you don't want them
      },
    },
  });

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  useEffect(() => {
    getEMARecord();
  }, []);

  const getEMARecord = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const res = await http.get(`api/admin/getEMA/${id}`, http.generateConfig(token));
      if (res) {
        console.log("res" , res)
        setData(res.data);
        prepareChartData(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prepareChartData = (records) => {
    const timestamps = [];
    const fraudScores = [];
    const emaScores = [];
    console.log("record" , records)
    records?.forEach(record => {
      timestamps.push(new Date(record.timestamp).toLocaleTimeString());
      fraudScores.push(record.fraudScore);
      emaScores.push(record.emaFraudScore !== null ? record.emaFraudScore : 0);
    });

    
    // Set the chart data with the new series data
    setChartData(prevState => ({
      ...prevState,
      series: [
        { name: "Fraud Score", data: fraudScores },
        { name: "EMA ", data: emaScores },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          categories: timestamps,
        },
        // Optionally set y-axis range to cover both positive and negative values
        yaxis: {
          min: Math.min(...fraudScores, ...emaScores) - 1,
          max: Math.max(...fraudScores, ...emaScores) + 1,
        },
      },
    }));
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Player EMA</h1>
          </Breadcrumb>
        </div>
        <div className="chart-container">
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerEMa;
