import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const SuspiciousConfirmationModal = ({
  user,
  onMark,
  onUnmark,
  markedUsers,
  setMarkedUsers,
  reason,
  setReason, 
}) => {
  const [showModal, setShowModal] = useState(false);
  const [otherReason, setOtherReason] = useState(''); // Separate state for custom input

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOtherReason(''); // Clear the custom input when the modal is closed
  };

  const handleConfirm = () => {
    const finalReason = reason === 'Other' ? otherReason : reason; // Use custom input if "Other" is selected
    if (user.isSuspicious === true) {
      onUnmark(user.id);
      setMarkedUsers(markedUsers.filter(id => id !== user.id));
    } else {
      onMark(user.id);
      setMarkedUsers([...markedUsers, user.id]);
    }
    console.log('Reason:', finalReason); // Log the final reason (for testing)
    handleCloseModal();
  };

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant={user.isSuspicious ? "danger" : "success"}
      >
        {user.isSuspicious ? "Not Suspicious" : "Suspicious"}
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" style={{ marginTop: "100px" }}>
        <Modal.Header closeButton>
          <Modal.Title>{user.isSuspicious ? "Mark as Not Suspicious" : "Mark as Suspicious"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            Are you sure you want to {user.isSuspicious ? <>mark <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b> as not suspicious?</> : <>mark <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b> as suspicious?</>}
            <br /><br />
            {/* Dropdown or input for reason */}
            <Form.Group controlId="reason">
              <Form.Label>Reason</Form.Label>
              <Form.Control
                as="select"
                value={reason}
                onChange={(e) => setReason(e.target.value)} // Handle reason change from parent state
              >
                <option value="">Select a reason</option>
                <option value="KYC review">KYC review</option>
                <option value="Activity review">Activity review</option>
                <option value="Other">Other (Please specify)</option>
              </Form.Control>
              {/* If "Other" is selected, show input field for custom reason */}
              {reason === 'Other' && (
                <Form.Control
                  type="text"
                  placeholder="Please specify"
                  onChange={(e) => setOtherReason(e.target.value)} // Update the custom input
                  value={otherReason}
                  className="mt-2"
                />
              )}
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {user.isSuspicious ? "Mark as Not Suspicious" : "Mark as Suspicious"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuspiciousConfirmationModal;
