import React, { useEffect, useState, useCallback } from "react";
import { throttle } from "lodash";
import {
  Breadcrumb,
  Button,
  Col,
  FormControl,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceInformation } from "../service/actions/deviceActions";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import ReactPaginate from "react-paginate";
import PlayerDetailModal from "../components/modal/PlayerDetailModal";

// Columns for the table
const columns = [
  { name: "ID", minWidth: "60px", key: "id" },
  { name: "Model", minWidth: "120px", key: "model" },
  { name: "OS", minWidth: "100px", key: "OS" },
  { name: "RAM", minWidth: "70px", key: "ram" },
  { name: "ROM", minWidth: "70px", key: "rom" },
  { name: "Device ID", minWidth: "90px", key: "deviceId" },
  { name: "Build Number", minWidth: "150px", key: "build_name" },
  { name: "Gyroscope", minWidth: "100px", key: "gyrpscope" },
  { name: "Accelerometer", minWidth: "100px", key: "accelerometer" },
  { name: "Player Detail", minWidth: "120px", key: "viewDetails" },
];

const DeviceInformation = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [gyrpscopeFilter, setGyrpscopeFilter] = useState(""); // All, Active, Disabled
  const [accelerometerFilter, setAccelerometerFilter] = useState(""); // All, Active, Disabled
  const [deviceIdFilter, setDeviceIdFilter] = useState(""); // Search by Device ID
  const [emailFilter, setEmailFilter] = useState(""); // Search by Email
  const [mobileSearchFilter, setMobileSearchFilter] = useState("");

  const [record, setRecord] = useState({});

  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 10;

  const dispatch = useDispatch();

  const device = useSelector((state) => state.device);


  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + 1);
  };

  const fetchDeviceInformation = useCallback(() => {
  console.log("Fetching device information...");
  dispatch(
    getDeviceInformation(
      currentPage,
      itemsPerPage,
      setPageCount,
      gyrpscopeFilter,
      accelerometerFilter,
      deviceIdFilter,
      emailFilter,
      mobileSearchFilter
    )
  ).then(() => {
    setLoading(false);
  });
}, [
  currentPage,
  itemsPerPage,
  gyrpscopeFilter,
  accelerometerFilter,
  deviceIdFilter,
  emailFilter,
  mobileSearchFilter,
]);

// Throttled function for handling search/filter changes
const throttledFetchDeviceInformation = useCallback(
  throttle(() => {
    console.log("Throttle triggered!");
    fetchDeviceInformation();
  }, 300),
  [fetchDeviceInformation] // Dependencies: ensure it gets updated when fetchDeviceInformation changes
);

  // Clean up throttled function on unmount
useEffect(() => {
  return () => {
    throttledFetchDeviceInformation.cancel();
  };
}, [throttledFetchDeviceInformation]);

  useEffect(() => {
    throttledFetchDeviceInformation(); // Call throttled function on dependency changes
  }, [
    currentPage,
    gyrpscopeFilter,
    accelerometerFilter,
    deviceIdFilter,
    emailFilter,
    mobileSearchFilter,
  ]);

  
  const handleDetailClick = (id) => {
    const data = device.filter((dev) => dev.id == id);

    if (data.length > 0) {
      setRecord(data[0]);
      setOpenDetailModal(true);
    }
  };


  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Device Information</h1>
          </Breadcrumb>
        </div>

        <div className="max-width-container" style={{ width: "100%", maxWidth: "99%", margin: "0 auto" }}>
          <Row>
            <Col md={12}>
              <div className="game-list">
                <div className="form-content-adduser container-fluid">
                  <h3>Devices</h3>
                  <div className="pagination-parent container-fluid">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousClassName={"previous"}
                      nextClassName={"next"}
                      activeClassName={"active"}
                      activeLinkClassName={"active"}
                      previousLinkClassName={"previous"}
                      nextLinkClassName={"next"}
                      disabledClassName={"disabled"}
                    />
                  </div>

                  <Row style={{ marginBottom: "20px" }} className="d-flex justify-content-between">
                    <Col className="d-flex flex-column align-items-start">
                      <strong style={{ fontSize: "16px" }}>DeviceID</strong>
                      <FormControl
                        style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
                        placeholder="Search by Device ID"
                        value={deviceIdFilter}
                        onChange={(e) => setDeviceIdFilter(e.target.value)}
                      />
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <strong style={{ fontSize: "16px" }}>Email</strong>
                      <FormControl
                        style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
                        placeholder="Search by Email"
                        value={emailFilter}
                        onChange={(e) => setEmailFilter(e.target.value)}
                      />
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <strong style={{ fontSize: "16px" }}>Mobile</strong>
                      <FormControl
                        style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
                        placeholder="Search by Mobile"
                        value={mobileSearchFilter}
                        onChange={(e) => setMobileSearchFilter(e.target.value)}
                      />
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <strong style={{ fontSize: "16px" }}>Gyroscope</strong>
                      <FormControl
                        style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
                        as="select"
                        onChange={(e) => setGyrpscopeFilter(e.target.value)}
                      >
                        <option value="">All Gyroscope</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </FormControl>
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <strong style={{ fontSize: "16px" }}>Accelerometer</strong>
                      <FormControl
                        style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)" }}
                        as="select"
                        onChange={(e) => setAccelerometerFilter(e.target.value)}
                      >
                        <option value="">All Accelerometer</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </FormControl>
                    </Col>
                  </Row>

                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <div>
                      <Table
                        className="align-middle"
                        bordered
                        hover
                        responsive
                        style={{
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          maxWidth: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            {columns?.map((column, index) => (
                              <th
                                key={index}
                                style={{
                                  minWidth: `${column.minWidth}`,
                                  maxWidth: "350px",
                                }}
                              >
                                {column.name}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {device?.map((row, rowIndex) => {
                            return (
                              <tr key={rowIndex}>
                                {columns?.map((column, colIndex) => (
                                  <td
                                    key={colIndex}
                                    className="text-capitalize"
                                  >
                                    {column.key === "viewDetails" ? (
                                      <button
                                        className="btn btn-primary rounded"
                                        onClick={() =>
                                          handleDetailClick(row.id)
                                        }
                                      >
                                        View Details
                                      </button>
                                    ) : column.key === "gyrpscope" ||
                                      column.key === "accelerometer" ? (
                                      row?.playerDeviceInformation[
                                        column.key
                                      ] ? (
                                        "True"
                                      ) : (
                                        "False"
                                      )
                                    ) : column.key === "ram" ||
                                      column.key === "rom" ? (
                                      isNaN(
                                        Number(
                                          row?.playerDeviceInformation[
                                            column.key
                                          ]
                                        )
                                      ) ? (
                                        "-"
                                      ) : (
                                        Number(
                                          row?.playerDeviceInformation[
                                            column.key
                                          ]
                                        ).toFixed(2)
                                      )
                                    ) : (
                                      row?.playerDeviceInformation?.[column.key]
                                    )}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <PlayerDetailModal
          show={openDetailModal}
          handleClose={() => setOpenDetailModal(false)}
          player={record}
        />
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default DeviceInformation;

